import React, {useCallback, useState} from "react";
import styles from "./download-button.module.scss";
import {Frame} from "../video-processor/frame.model";
import ReactGA from "react-ga";

export interface DownloadButtonProps {
    frames: Frame[];
    videoWidth: number;
    videoHeight: number;
}

export function DownloadButton({frames, videoWidth, videoHeight}: DownloadButtonProps) {
    const [loading, setLoading] = useState(false);

    const downloadPdf = useCallback(() => {
        setLoading(true);

        ReactGA.event({
            category: 'Download',
            action: 'start',
        });

        window.setTimeout(async () => {
            const {jsPDF} = await import('jspdf');
            const pdf = new jsPDF({
                orientation: videoWidth > videoHeight ? 'l' : 'p',
                unit: 'px',
                format: [videoWidth, videoHeight],
            });

            for (let i = 0; i < frames.length; i++) {
                if (i > 0) {
                    pdf.addPage();
                }

                pdf.addImage(frames[i].png, 'JPEG', 0, 0, videoWidth, videoHeight);
            }

            try {
                pdf.save('slides_slideextract_com.pdf');

                ReactGA.event({
                    category: 'Download',
                    action: 'success',
                });
            } catch (e) {
                window.alert("Failed to download PDF. Please adjust the threshold to reduce the number of slides and try again.");

                ReactGA.event({
                    category: 'Download',
                    action: 'failure',
                });
            }

            setLoading(false);
        }, 250);
    }, [frames, videoHeight, videoWidth]);

    if (!loading) {
        return <label className={`${styles['download-button']} ${styles.active}`} onClick={downloadPdf}>
            Download PDF
        </label>;
    } else {
        return <label className={`${styles['download-button']} ${styles.processing}`}>
            Generating PDF...
        </label>;
    }
}
