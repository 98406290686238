import React, {useCallback, useMemo, useState} from 'react';
import styles from './app.module.scss';
import {DownloadButton} from "./components/DownloadButton";
import {PreviewGallery} from "./components/PreviewGallery";
import {ThresholdPicker} from "./components/ThresholdPicker";
import {Frame} from "./video-processor/frame.model";
import {UploadButton} from "./components/UploadButton";
import {ProcessVideoResult} from "./video-processor/process-video";
import ReactGA from "react-ga";

const PROCESSOR_THRESHOLD = 1;

const filterFramesByThreshold = (frames: Frame[], threshold: number): Frame[] => {
    return frames.filter(({diff}) => diff === undefined || diff >= threshold);
};

enum DisplayState {
    INITIAL,
    PROCESSING,
    COMPLETE,
}

function App() {
    const [dragging, setDragging] = useState(false);
    const [displayState, setDisplayState] = useState<DisplayState>(DisplayState.INITIAL);
    const [progress, setProgress] = useState(0);

    const [threshold, setThreshold] = useState(1);
    const [frames, setFrames] = useState<ProcessVideoResult>({frames: [], width: 0, height: 0});
    const selectedFrames = useMemo(() => filterFramesByThreshold(frames.frames, threshold), [frames, threshold]);

    const updateFile = useCallback(async (newFile: File) => {
        if (displayState !== DisplayState.INITIAL) {
            if (!window.confirm("Do you really want to replace the file?")) return;
        }

        setDisplayState(DisplayState.PROCESSING);
        setProgress(0);

        ReactGA.event({
            category: 'Upload',
            action: 'start',
        });

        const {processVideo} = await import("./video-processor/process-video");
        const processVideoResult = await processVideo(newFile, PROCESSOR_THRESHOLD, setProgress);

        ReactGA.event({
            category: 'Upload',
            action: 'finish',
        });

        setFrames(processVideoResult);
        setDisplayState(DisplayState.COMPLETE);
    }, [displayState]);

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!dragging) {
            setDragging(true);
        }
    }, [dragging]);

    const handleDragLeave = useCallback(() => {
        setDragging(false);
    }, []);

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (e.dataTransfer.files.length > 0) {
            const droppedFile = e.dataTransfer.files[0];
            if (droppedFile.type.startsWith("video/")) {
                updateFile(droppedFile);
            } else {
                window.alert("The dropped file is not compatible. Please drop a video file.");
            }
        }

        setDragging(false);
    };

    return (
        <div
            className={`${styles.page} ${dragging ? styles.dragging : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}>
            <div className={styles.container}>
                <a href="/">
                    <div className={styles.logo}>
                        <span className={styles['logo-slide']}>slide</span><span
                        className={styles['logo-extract']}>extract</span>
                    </div>
                </a>

                <div className={`${styles.step} ${styles.active}`}>
                    <h2>
                        <div className={styles['step-no']}>1</div>
                        Select Video
                    </h2>

                    <div>
                        <p>
                            Slide Extract lets you easily convert presentation videos or whiteboard recordings into
                            PDF documents, right on your device.
                            <br/><br/>
                            All processing happens locally, so your video won't be
                            sent to the cloud. Just select a video, fine-tune the settings, and let
                            Slide Extract do the magic.
                        </p>

                        <UploadButton processingProgress={displayState === DisplayState.PROCESSING ? progress : null}
                                      hasVideo={displayState === DisplayState.COMPLETE}
                                      onUpload={updateFile}/>
                    </div>
                </div>

                <div className={`${styles.step} ${displayState === DisplayState.COMPLETE ? styles.active : ''}`}>
                    <h2>
                        <div className={styles['step-no']}>2</div>
                        Adjust Threshold
                    </h2>

                    <div>
                        <p>
                            Use the slider to adjust the threshold for detecting changes
                            between video frames. The lower the threshold, the more slides will be extracted.
                            <br/><br/>
                            <b>Usually, there's no need to change the default value.</b>
                        </p>

                        <ThresholdPicker frames={frames.frames} threshold={threshold} setThreshold={setThreshold}/>
                    </div>
                </div>

                <div className={`${styles.step} ${displayState === DisplayState.COMPLETE ? styles.active : ''}`}>
                    <h2>
                        <div className={styles['step-no']}>3</div>
                        Preview{/*&amp; Select*/}
                    </h2>

                    <div>
                        <p>
                            You may now preview the extracted slides. Select a thumbnail to enlarge it.
                            {/*You may now preview the extracted slides. Select a thumbnail to enlarge it or click the*/}
                            {/*button in the top right corner to exclude it from the final PDF.*/}
                        </p>

                        {displayState === DisplayState.COMPLETE && <PreviewGallery frames={selectedFrames}/>}
                    </div>
                </div>

                <div className={`${styles.step} ${displayState === DisplayState.COMPLETE ? styles.active : ''}`}>
                    <h2>
                        <div className={styles['step-no']}>4</div>
                        Download PDF
                    </h2>

                    <div>
                        <p>
                            You're all set. Click the button below to download the PDF.
                        </p>

                        <DownloadButton frames={selectedFrames} videoWidth={frames.width} videoHeight={frames.height}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
