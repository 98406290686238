import React, {useCallback, useMemo} from "react";
import styles from "./threshold-picker.module.scss";
import {Frame} from "../video-processor/frame.model";
import ReactGA from "react-ga";

export interface ThresholdPickerProps {
    frames: Frame[];
    threshold: number;
    setThreshold: (threshold: number) => void;
}

export function ThresholdPicker({frames, threshold, setThreshold}: ThresholdPickerProps) {
    const {bins, binSize, minThreshold, maxThreshold} = useMemo(() => {
        const {min, max} = frames.reduce(({min, max}, frame) => (
            {min: Math.min(min, frame.diff || Infinity), max: Math.max(max, frame.diff || -Infinity)}
        ), {min: Infinity, max: -Infinity});

        const range = max - min;
        const binSize = range / 30;

        let maxBinValue = 1;
        const bins = new Array(30).fill(0);

        frames.forEach(({diff}) => {
            if (diff !== undefined) {
                let binIndex = Math.min(Math.floor((diff - min) / binSize), bins.length - 1);
                maxBinValue = Math.max(maxBinValue, ++bins[binIndex]);
            }
        });

        return {
            bins: bins.map((bin) => bin / maxBinValue),
            binSize,
            minThreshold: min !== Infinity ? Math.floor(min) : null,
            maxThreshold: max !== -Infinity ? Math.ceil(max) : null
        };
    }, [frames]);

    const binThreshold = useMemo(() => (
        minThreshold === null ? null : Math.floor((threshold - minThreshold) / binSize)
    ), [threshold, minThreshold, binSize]);

    const barWidth = 100 / bins.length;

    const onThresholdChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);

        ReactGA.event({
            category: 'Threshold',
            action: 'change',
            value,
        });

        setThreshold(value);
    }, [setThreshold]);

    if (minThreshold === null || maxThreshold === null || binThreshold === null) {
        return <div className={styles['no-thr-box']}>
            No configuration options available for this video
        </div>;
    }

    return <>
        <div className={styles.histogram}>
            {bins.map((binValue, index) => (
                <div
                    key={index}
                    className={`${styles['histogram-bar']} ${index >= binThreshold ? styles.selected : ''}`}
                    style={{
                        height: `${binValue * 100}%`,
                        width: `calc(${barWidth}% - 1px)`,
                        left: `${barWidth * index}%`
                    }}
                />
            ))}
        </div>

        <input
            type="range"
            min={minThreshold}
            max={maxThreshold}
            value={threshold}
            onChange={onThresholdChange}
            style={{width: "100%"}}
        />
    </>;
}
