import styles from "./upload-button.module.scss";
import React, {useCallback} from "react";

export interface UploadButtonProps {
    processingProgress: number | null;
    hasVideo: boolean;
    onUpload: (file: File) => void;
}

const iPhoneUser = navigator.userAgent.includes("iPhone");

export function UploadButton({processingProgress, hasVideo, onUpload}: UploadButtonProps) {
    const onFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null && e.target.files.length > 0) {
            onUpload(e.target.files[0]);
        }
    }, [onUpload]);

    if (iPhoneUser) {
        return <div className={styles['iphone-box']}>
            Unfortunately, iPhones are not yet supported. Please use an iPad, Android device, or computer.
        </div>;
    }

    if (processingProgress !== null) {
        const progress = processingProgress * 100;

        return <div className={styles['upload-container']}>
            <label className={`${styles['action-button']} ${styles.processing}`}
                   style={{background: `linear-gradient(to right, #4caf50 0%, #4caf50 ${progress}%, whitesmoke ${progress}%, whitesmoke 100%)`}}>
                Processing...
            </label>
        </div>;
    }

    return <div className={styles['upload-container']}>
        <input
            type="file"
            id="upload-file"
            accept="video/*"
            onChange={onFileChange}/>

        <label htmlFor="upload-file" className={`${styles['action-button']} ${styles.initial}`}>
            {hasVideo ? "Replace Video" : "Select Video"}
        </label>
        <span className={styles.subtext}>Or drag file anywhere</span>
    </div>;
}
