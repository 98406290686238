import React, {useCallback, useState} from "react";
import styles from "./preview-gallery.module.scss";
import {Frame} from "../video-processor/frame.model";
import ReactGA from "react-ga";

export interface PreviewGalleryProps {
    frames: Frame[];
}

export function PreviewGallery({frames}: PreviewGalleryProps) {
    const [selectedFrame, setSelectedFrame] = useState<Frame>(frames[0]);

    const onThumbnailClick = useCallback((frame: Frame) => {
        ReactGA.event({
            category: 'Gallery',
            action: 'thumbnail_click',
        });

        setSelectedFrame(frame);
    }, [setSelectedFrame]);

    return (
        <div className={styles.gallery}>
            <div className={styles.counter}>{frames.length}</div>

            <img className={styles['gallery-preview']} src={selectedFrame.png} alt="Slide preview"/>

            <div className={styles.thumbnails}>
                {frames.map((frame, index) => (
                    <div key={index}
                         className={`${styles.thumbnail} ${frame === selectedFrame ? styles.selected : ""}`}>
                        <img src={frame.png} onClick={() => onThumbnailClick(frame)} alt={`Thumbnail ${index + 1}`}/>
                        {/*<button className={`${styles['ignore-button']}`}*/}
                        {/*        onClick={(e) => {*/}
                        {/*            e.stopPropagation();*/}
                        {/*            // toggleIgnore(index);*/}
                        {/*        }}>*/}
                        {/*    {{frame.hidden ? "✓" : "✕"}}*/}
                        {/*</button>*/}
                    </div>
                ))}
            </div>
        </div>
    );
}
